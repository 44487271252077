const main = async () => {
    // async load axios module
    const { default: axios } = await import(/* webpackChunkName: "axios" */ 'axios');

    const fetchVimeoAlbum = async (limit) => {
        try {
            const response = await axios.get('/actions/vimeo-api-module/proxy/get-vimeo-album', {
                params: {}
            });
            // return response from vimeo api module
            return response.data.data.slice(0,limit);
        } catch (errors) {
            console.error(errors);
        }
    }

    const createVideoElement = item => {
        let title = item.name;
        let videoDate = new Date(item.created_time);
        let convertedDate = videoDate.toLocaleString('default', { month: 'long', day: '2-digit', year: 'numeric' });
        let description = item.description;
        let videoId = item.uri.split('/videos/')[1];
        let videoUrl = 'https://player.vimeo.com/video/' + videoId;
        let url = item.link;

        const videoWrapper = document.createElement('div');
        videoWrapper.classList.add('row', 'mx-0');

        videoWrapper.innerHTML = `
            <div class='col-xmd-6 position-relative bg-light px-0 d-flex flex-column justify-content-center'>
              <div class='video-embed-container'><iframe src='${videoUrl}' frameborder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe></div>
            </div>
            <div class='col-xmd-6 px-0 position-relative bg-white'>
                <div class='d-flex flex-column justify-content-center position-relative two-column-copy copy-z-1'>
                    <div>
                        <h2 class='h3 heading-arrow'>${title}</h2>
                        <p class='mb-4'>${description}</p>
                        <a class='arrow-link' href='/news-media/south-dakota-ag-minute' aria-label='View more South Dakota Ag Minute videos'>View More Videos</a>
                    </div>
                </div>
            </div>
        `;
        // return video element
        return videoWrapper;
    }

    const createVideoList = videoItems => {
        const videoList = document.querySelector('.home-ag-minute-section .home-ag-minute-container');

        if (Array.isArray(videoItems) && videoItems.length > 0) {
            videoItems.map(videoItem => {
                videoList.appendChild(createVideoElement(videoItem));
            });
        } else if (videoItems) {
            videoList.appendChild(createVideoElement(videoItems));
        }
    }
    // return array of videos
    return createVideoList(await fetchVimeoAlbum(1));
};

main().then(() => {
    let placeholderCards = document.querySelectorAll('.home-ag-minute-section .placeholder-card-wrap');
    placeholderCards.forEach(el => {
        el.remove();
    });
});